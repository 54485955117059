var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", { attrs: { "body-style": { padding: "25px" } } }, [
        _c("div", { attrs: { slot: "header" }, slot: "header" }, [
          _c(
            "div",
            { staticStyle: { "margin-left": "0px", display: "flex" } },
            [
              _c(
                "div",
                { staticClass: "select2" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "130px" },
                      attrs: { slot: "prepend" },
                      slot: "prepend",
                      model: {
                        value: _vm.selectTipoBusqueda,
                        callback: function($$v) {
                          _vm.selectTipoBusqueda = $$v
                        },
                        expression: "selectTipoBusqueda"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Técnico", value: 1 }
                      }),
                      _c("el-option", { attrs: { label: "Cliente", value: 2 } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buscador2" },
                [
                  _c("maca-select-box", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectTipoBusqueda == 1,
                        expression: "selectTipoBusqueda == 1"
                      }
                    ],
                    attrs: {
                      url: _vm.urlTecnico,
                      clearable: true,
                      necesitaParams: true,
                      getParams: _vm.getParamsTecnico,
                      itemLabel: "username",
                      placeholder: "Seleccione técnico"
                    },
                    on: {
                      change: function($event) {
                        return _vm.actualizarTablaFuncion()
                      }
                    },
                    model: {
                      value: _vm.tecnico,
                      callback: function($$v) {
                        _vm.tecnico = $$v
                      },
                      expression: "tecnico"
                    }
                  }),
                  _c("maca-select-box", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectTipoBusqueda == 2,
                        expression: "selectTipoBusqueda == 2"
                      }
                    ],
                    attrs: {
                      url: _vm.urlCliente,
                      clearable: true,
                      necesitaParams: true,
                      getParams: _vm.getParamsCliente,
                      permitirInput: true,
                      itemLabel: ["nombre", "telefono"],
                      placeholder: "Buscar por teléfono"
                    },
                    on: {
                      change: function($event) {
                        return _vm.actualizarTablaFuncion()
                      }
                    },
                    model: {
                      value: _vm.cliente,
                      callback: function($$v) {
                        _vm.cliente = $$v
                      },
                      expression: "cliente"
                    }
                  })
                ],
                1
              )
            ]
          )
        ]),
        _c(
          "div",
          [
            _c("h4", { staticStyle: { color: "#909399" } }, [
              _vm._v("Reservas")
            ]),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.cargando,
                    expression: "cargando"
                  }
                ],
                attrs: { data: _vm.detalleReserva, stripe: "" }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "Fecha" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$maca.common.formatearFecha(
                                  props.row.detalleTurnoTecnico.detalleTurno
                                    .detalleTurnoGeneral.fecha
                                )
                              ) + " - "
                            )
                          ]),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$maca.common.formatearHora(
                                  props.row.detalleTurnoTecnico.detalleTurno
                                    .detalleTurnoGeneral.horaInicio
                                ) +
                                  " a " +
                                  _vm.$maca.common.formatearHora(
                                    props.row.detalleTurnoTecnico.detalleTurno
                                      .detalleTurnoGeneral.horaFin
                                  )
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Ubicación" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                props.row.detalleTurnoTecnico.detalleTurno
                                  .detalleTurnoGeneral.detalleArea.descripcion
                              )
                            )
                          ]),
                          _c("span", [
                            _vm._v(
                              ", " +
                                _vm._s(
                                  props.row.detalleTurnoTecnico.detalleTurno
                                    .detalleTurnoGeneral.detalleSubrea
                                    .descripcion
                                )
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              "Barrio: " +
                                _vm._s(props.row.detalleSeccion.descripcion)
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Cliente" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", [
                            _vm._v(" Nombre: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(props.row.detalleClienteReserva.nombre)
                              )
                            ])
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(" Dirección: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  props.row.detalleClienteReserva.domicilio
                                )
                              )
                            ])
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(" Teléfono: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(props.row.detalleClienteReserva.telefono)
                              )
                            ])
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Servicio" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(props.row.descripcionServicio)
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Importe" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", [
                            _vm._v(
                              "$ " +
                                _vm._s(
                                  (
                                    parseFloat(props.row.montoProductos) +
                                    parseFloat(props.row.montoServicios)
                                  ).toFixed(2)
                                ) +
                                " "
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Estado", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          props.row.realizado
                            ? _c("div", [_c("span", [_vm._v("Realizado")])])
                            : _c("div", [
                                props.row.confirmado
                                  ? _c("div", [
                                      _c("span", [_vm._v("Confirmado")])
                                    ])
                                  : _c("div", [
                                      _c("span", [_vm._v("Pendiente")])
                                    ])
                              ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c("h4", { staticStyle: { color: "#909399" } }, [
              _vm._v("Retiros")
            ]),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.cargando,
                    expression: "cargando"
                  }
                ],
                attrs: { data: _vm.detalleRetiro, stripe: "" }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "Fecha" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$maca.common.formatearFecha(
                                  props.row.detalleRangoRetiro.fecha
                                )
                              ) + " - "
                            )
                          ]),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$maca.common.formatearHora(
                                  props.row.horaRetiroAprox
                                )
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Ubicación" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                props.row.detalleRangoRetiro.detalleSubrea
                                  .detalleArea.descripcion
                              )
                            )
                          ]),
                          _c("span", [
                            _vm._v(
                              ", " +
                                _vm._s(
                                  props.row.detalleRangoRetiro.detalleSubrea
                                    .descripcion
                                )
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              "Barrio: " +
                                _vm._s(props.row.detalleSeccion.descripcion)
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Cliente" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", [
                            _vm._v(" Nombre: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(props.row.detalleClienteReserva.nombre)
                              )
                            ])
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(" Dirección: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  props.row.detalleClienteReserva.domicilio
                                )
                              )
                            ])
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(" Teléfono: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(props.row.detalleClienteReserva.telefono)
                              )
                            ])
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Servicio" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(props.row.descripcionServicio)
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Importe" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", [
                            _vm._v(
                              "$ " +
                                _vm._s(
                                  (
                                    parseFloat(props.row.montoProductos) +
                                    parseFloat(props.row.montoServicios)
                                  ).toFixed(2)
                                ) +
                                " "
                            )
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Estado", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          props.row.devuelto
                            ? _c("div", [_c("span", [_vm._v("Devuelto")])])
                            : _c("div", [
                                props.row.realizado
                                  ? _c("div", [
                                      _c("span", [_vm._v("Realizado")])
                                    ])
                                  : _c("div", [
                                      props.row.retirado
                                        ? _c("div", [
                                            _c("span", [_vm._v("Retirado")])
                                          ])
                                        : _c("div", [
                                            props.row.confirmado
                                              ? _c("div", [
                                                  _c("span", [
                                                    _vm._v("Confirmado")
                                                  ])
                                                ])
                                              : _c("div", [
                                                  _c("span", [
                                                    _vm._v("Pendiente")
                                                  ])
                                                ])
                                          ])
                                    ])
                              ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }