<template>
  <div>
    <el-card :body-style="{ padding: '25px' }">
      <div slot="header">
        <div style="margin-left: 0px; display: flex">
          <div class="select2">
            <el-select
              v-model="selectTipoBusqueda"
              slot="prepend"
              style="width: 130px"
            >
              <el-option label="Técnico" :value="1"></el-option>
              <el-option label="Cliente" :value="2"></el-option>
            </el-select>
          </div>
          <div class="buscador2">
            <maca-select-box
              v-show="selectTipoBusqueda == 1"
              :url="urlTecnico"
              v-model="tecnico"
              :clearable="true"
              :necesitaParams="true"
              :getParams="getParamsTecnico"
              itemLabel="username"
              placeholder="Seleccione técnico"
              @change="actualizarTablaFuncion()"
            >
            </maca-select-box>
            <maca-select-box
              v-show="selectTipoBusqueda == 2"
              :url="urlCliente"
              v-model="cliente"
              :clearable="true"
              :necesitaParams="true"
              :getParams="getParamsCliente"
              :permitirInput="true"
              :itemLabel="['nombre', 'telefono']"
              placeholder="Buscar por teléfono"
              @change="actualizarTablaFuncion()"
            >
            </maca-select-box>
          </div>
        </div>
      </div>

      <div>
        <h4 style="color: #909399">Reservas</h4>
        <el-table :data="detalleReserva" stripe v-loading="cargando">
          <el-table-column label="Fecha">
            <template slot-scope="props">
              <span
                >{{
                  $maca.common.formatearFecha(
                    props.row.detalleTurnoTecnico.detalleTurno
                      .detalleTurnoGeneral.fecha
                  )
                }}
                -
              </span>
              <span
                v-text="
                  $maca.common.formatearHora(
                    props.row.detalleTurnoTecnico.detalleTurno
                      .detalleTurnoGeneral.horaInicio
                  ) +
                  ' a ' +
                  $maca.common.formatearHora(
                    props.row.detalleTurnoTecnico.detalleTurno
                      .detalleTurnoGeneral.horaFin
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column label="Ubicación">
            <template slot-scope="props">
              <span>{{
                props.row.detalleTurnoTecnico.detalleTurno.detalleTurnoGeneral
                  .detalleArea.descripcion
              }}</span>
              <span
                >,
                {{
                  props.row.detalleTurnoTecnico.detalleTurno.detalleTurnoGeneral
                    .detalleSubrea.descripcion
                }}</span
              >
              <p>Barrio: {{ props.row.detalleSeccion.descripcion }}</p>
            </template>
          </el-table-column>

          <el-table-column label="Cliente">
            <template slot-scope="props">
              <span>
                Nombre: <b>{{ props.row.detalleClienteReserva.nombre }}</b>
              </span>
              <br />
              <span>
                Dirección:
                <b>{{ props.row.detalleClienteReserva.domicilio }}</b>
              </span>
              <br />
              <span>
                Teléfono: <b>{{ props.row.detalleClienteReserva.telefono }}</b>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Servicio">
            <template slot-scope="props">
              <span v-text="props.row.descripcionServicio"></span>
            </template>
          </el-table-column>
          <el-table-column label="Importe">
            <template slot-scope="props">
              <span
                >$
                {{
                  (
                    parseFloat(props.row.montoProductos) +
                    parseFloat(props.row.montoServicios)
                  ).toFixed(2)
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Estado" align="center">
            <template slot-scope="props">
              <div v-if="props.row.realizado">
                <span>Realizado</span>
              </div>
              <div v-else>
                <div v-if="props.row.confirmado">
                  <span>Confirmado</span>
                </div>
                <div v-else>
                  <span>Pendiente</span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <h4 style="color: #909399">Retiros</h4>
        <el-table :data="detalleRetiro" stripe v-loading="cargando">
          <el-table-column label="Fecha">
            <template slot-scope="props">
              <span
                >{{
                  $maca.common.formatearFecha(
                    props.row.detalleRangoRetiro.fecha
                  )
                }}
                -
              </span>
              <span
                v-text="$maca.common.formatearHora(props.row.horaRetiroAprox)"
              ></span>
            </template>
          </el-table-column>
          <el-table-column label="Ubicación">
            <template slot-scope="props">
              <span>{{
                props.row.detalleRangoRetiro.detalleSubrea.detalleArea
                  .descripcion
              }}</span>
              <span
                >,
                {{
                  props.row.detalleRangoRetiro.detalleSubrea.descripcion
                }}</span
              >
              <p>Barrio: {{ props.row.detalleSeccion.descripcion }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Cliente">
            <template slot-scope="props">
              <span>
                Nombre: <b>{{ props.row.detalleClienteReserva.nombre }}</b>
              </span>
              <br />
              <span>
                Dirección:
                <b>{{ props.row.detalleClienteReserva.domicilio }}</b>
              </span>
              <br />
              <span>
                Teléfono: <b>{{ props.row.detalleClienteReserva.telefono }}</b>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Servicio">
            <template slot-scope="props">
              <span v-text="props.row.descripcionServicio"></span>
            </template>
          </el-table-column>
          <el-table-column label="Importe">
            <template slot-scope="props">
              <span
                >$
                {{
                  (
                    parseFloat(props.row.montoProductos) +
                    parseFloat(props.row.montoServicios)
                  ).toFixed(2)
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Estado" align="center">
            <template slot-scope="props">
              <div v-if="props.row.devuelto">
                <span>Devuelto</span>
              </div>
              <div v-else>
                <div v-if="props.row.realizado">
                  <span>Realizado</span>
                </div>
                <div v-else>
                  <div v-if="props.row.retirado">
                    <span>Retirado</span>
                  </div>
                  <div v-else>
                    <div v-if="props.row.confirmado">
                      <span>Confirmado</span>
                    </div>
                    <div v-else>
                      <span>Pendiente</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "producto",
  components: {
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: "",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      filtroDescripcion: null,
      selectTipoBusqueda: 2,
      urlTecnico: "/user/obtenerTodos",
      urlCliente: "/clienteReserva/obtenerTodos",
      detalleReserva: [],
      detalleRetiro: [],
      tecnico: null,
      cliente: null,
      cargando: false,
    };
  },
  methods: {
    async cargarTabla() {
      this.detalleRetiro = [];
      this.detalleReserva = [];

      this.cargando = true;

      // Hacer post

      let respuestaApi = await this.$maca.api.get(
        this.urlTabla,
        this.paramsTabla
      );
      //

      this.cargando = false;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.detalleReserva = respuestaApi.datos.detalleReservas;
        this.detalleRetiro = respuestaApi.datos.detalleRetiros;
      } else {
      }
      //
    },
    getParamsTecnico() {
      let params = { rolID: 5 };
      return params;
    },
    getParamsCliente(query) {
      let params = { telefono: query };
      return params;
    },
    actualizarTablaFuncion() {
      if (this.selectTipoBusqueda == 1) {
        if (this.tecnico) {
          this.urlTabla = "/reserva/historial";
          this.paramsTabla = { userID: this.tecnico.id };
        } else {
          this.urlTabla = "";
          this.paramsTabla = {};
        }
        this.cargarTabla();
      } else if (this.selectTipoBusqueda == 2) {
        if (this.cliente) {
          this.urlTabla = "/reserva/historial";
          this.paramsTabla = { clienteID: this.cliente.id };
        } else {
          this.urlTabla = "";
          this.paramsTabla = {};
        }
        this.cargarTabla();
      }
    },
  },
};
</script>
